import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { School, Majorlist, getIttnxm, FollwupAdd, Customerinfo, getLabelpeer, FollwupIndex as _FollwupIndex, getEntrance, BrochuresList as _BrochuresList, MyShareSetshare, MyCustomerIndex, MyCustomerUpdate, getCustomerLevel, getGraduationMajor, agentFlowerNameList, MyCustomerRegression, MyCustomerCustomerlog, MyCustomerTransfoll, MyCustomerTransfer, orderlist, callout, getCallLog as _getCallLog } from '@/api/ceshi_xiugai/whole';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import { getUnfollow } from '@/api/PublicAPI';
import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import mailList from '@/components/StaffSelect/mailListRadio';
import { getInfo } from '@/api/login';
import { log } from 'video.js';
export default {
  components: {},
  data: function data() {
    var _ref;
    return _ref = {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      title: '通话记录',
      page: 1,
      zong: 0,
      aaa: true,
      hujiao: 0,
      col: '',
      pagesize: 15,
      totle: 0,
      qwerqwre: 0,
      keyword: '',
      last_page: 0,
      left: true,
      shuArr: false,
      right: false,
      queryShow: false,
      fullscreenLoading: false,
      tabClass: 1,
      recordArr: [],
      activeCall: 'first',
      radioUp: false,
      //转移跟进人
      mailListVisibleUp: false,
      Unfollow: '',
      //下次会发条数
      get_type: 0,
      Unfollowshow: false,
      //禁止改
      disable: false,
      wxdisable: false,
      tgmcdisable: false,
      tgdzdisable: false,
      mo1disable: false,
      mo2disable: false,
      noteApp: false,
      distribution: this.$route.query.distribution == 2 || this.$route.query.distribution == 3 ? true : false,
      customer_id: '',
      customer_data_id: '',
      editRole: 1,
      create: '',
      edite: '',
      activeNames: ['0'],
      //默认收齐状态
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      staffResultEs: [],
      radioEs: false,
      mailListVisibleEs: false,
      reverse: true,
      isNullCphone: false,
      isNullwxnum: false,
      isNullCphonetwo: false,
      dialogFormVisible: false,
      isNullCphonethree: false,
      isusertype: false,
      loading: false,
      radios: false,
      show2: false,
      show3: false,
      regression: true,
      //公海、私海
      mailListVisible: false,
      //创建人通讯录开关
      staffResult: [],
      //共享通讯录选取的值
      condition: []
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "page", 1), "pagesize", 15), "isusertypeName", '查看全部'), "journal", []), "enteredBy", ''), "Intended", ''), "General", ''), "ins", ''), "IntendedStation", []), "IntendedMajor", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "GeneralStation", []), "GeneralRules", []), "instituStation", []), "institu", []), "flowerNumber", []), "entranceTextEs", ''), "flowerNumberStation", []), "detailForm", {
      name: '',
      //名称
      flower_name: '',
      //花名
      flowerNameId: 0,
      //花名id
      category: '',
      //类别
      agent_num: '',
      //花名码
      Telephone: '',
      //电话1
      cphoneAdress: '',
      //归属地1
      Telephone2: '',
      //电话2
      cphoneAdress2: '',
      //归属地2
      Telephone3: '',
      //电话3
      cphoneAdress3: '',
      //归属地3
      mailbox: '',
      //邮箱
      QQ: '',
      //qq
      remarks: '',
      //备注
      appended: '',
      WeChat: '',
      //微信
      extensionName: '',
      //推广名称
      extensionAddress: '',
      //推广地址
      educationText: '',
      //当前学历
      areaOfInterest: '',
      //意向地区
      projectTextId: '',
      //报考项目id
      projectText: '',
      //报考项目
      GraduationMajor: '',
      //毕业专业
      IntendedMajor: [],
      //意向专业
      GeneralRules: [],
      //意向简章
      GraduatedFrom: '',
      //毕业院校
      institu: [],
      //意向院校所选的值
      budget: '',
      //预算
      sex: '',
      //性别
      entranceText: '',
      //预计入学id
      entranceTexts: '',
      //预计入学val
      post: '',
      //岗位
      achievements: '',
      //学术成绩
      Age: '',
      //年龄
      Landline: '',
      //座机
      occupation: '',
      //单位
      language: '',
      //语言成绩
      industry: '',
      //行业
      Passport: '',
      //护照号
      // communication: '', //发起沟通的网址
      hobby: '',
      //性格
      address: '',
      //详细地址
      labelpeer: [],
      //客户标签
      Student: [] //学员级别
    }), "baokaixiangmu", ''), "educationText", ''), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "detailFormShow", {
      cname: true,
      intentionally: true,
      cphone: true,
      agent_name: true,
      area: true,
      noteAppEs: true,
      adress: true,
      note: true,
      wxnum: true,
      communication_name: true,
      communication_website: true,
      telephone: true,
      cemail: true,
      qq: true,
      cphonetwo: true,
      cphonethree: true,
      ittnzy: true,
      ittnzyShow: true,
      ittnxl: true,
      ittnxlShow: true,
      ittnyx: true,
      ittnyxShow: true,
      ittnxm: true,
      ittnxmShow: true,
      ittngj: true,
      budget: true,
      xuel: true,
      graduate: true,
      graduatezy: true,
      xueshuchengji: true,
      yuyanchengji: true,
      timeline: true,
      sex: true,
      age: true,
      station: true,
      industry: true,
      character: true,
      occupation: true,
      hzID: true,
      hobby: true,
      tolink: true,
      next_follow: true,
      groupName: true,
      groupNameShow: true
    }), "form", {
      next_follow: '',
      //下次回访日期
      theme: '',
      typese: '1'
    }), "rules", {
      // theme: [{ required: true, message: '主题不能为空', trigger: 'blur' }],
    }), "flower_Array", []), "Intendeds", []), "GenalRules", []), "chatmodes", []), "activities", []), "projects", []), "entrance", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "educations", []), "StudentLevel", []), "customer", []), "transferorid", ''), "radio", false), "tableData", []), "total", 0), "charge_type", ''), "orderPagesize", 10), "orderPage", 1), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "activeName", 'customerInfo'), "detailListShow", false), "addOrderShow", false), "editOrderShow", false), "orderDetailShow", false), "ajsgh", true);
  },
  created: function created() {
    var _this = this;
    getInfo().then(function (res) {
      _this.charge_type = res.data.charge_type;
    });
  },
  mounted: function mounted() {
    this.TabClick(1);
    this.create = this.$route.query.create;
    this.edite = this.$route.query.edit;
    this.education();
    this.showTab();
    // this.dealOrder();
    this.disabledShow();
    this.col = this.$store.getters.TianRun.cno;
    if (this.col != '') {
      this.aaa = true;
    } else {
      this.aaa = false;
    }
  },
  filters: {
    pay_purpose: function pay_purpose(num) {
      if (num == 1) {
        return '报名费';
      } else if (num == 2) {
        return '定金';
      } else if (num == 3) {
        return '学费';
      } else if (num == 4) {
        return '资料费';
      } else if (num == 5) {
        return '语言培训费';
      } else if (num == 6) {
        return '普通留学申请费';
      }
    },
    periodization: function periodization(num) {
      if (num == 1) {
        return '一次性全款';
      } else if (num == 2) {
        return '分两次全款';
      } else if (num == 3) {
        return '分三次全款';
      } else if (num == 4) {
        return '分四次全款';
      }
    },
    periodization_set: function periodization_set(num) {
      if (num == 1) {
        return '第一次付款';
      } else if (num == 2) {
        return '第二次付款';
      } else if (num == 3) {
        return '第三次付款';
      } else if (num == 4) {
        return '第四次付款';
      } else if (num == 5) {
        return '一次性付全款';
      }
    }
  },
  methods: {
    TabClick: function TabClick(val) {
      // if (this.ajsgh) {
      this.tabClass = val;
      this.page = 1;
      this.qwerqwre = 0;
      this.keyword = '';
      if (val == 2) {
        this.queryShow = true;
      } else {
        this.queryShow = false;
      }
      this.recordArr = [];
      this.getCallLog(this.page, this.pagesize, val, 0);
      this.FollwupIndex(this.customer_id, this.customer_data_id);
      // } else {
      //     this.$notify({
      //         title: '提示',
      //         message: '数据正在加载中，请稍等~'
      //     });
      // }
    },
    activeClick: function activeClick(index) {
      this.qwerqwre = index;
      this.getCallLog(this.page, this.pagesize, this.tabClass, index);
    },
    getCallLog: function getCallLog(page, pagesize, type, index) {
      var _this2 = this;
      this.fullscreenLoading = true;
      this.ajsgh = false;
      _getCallLog({
        page: page,
        pagesize: pagesize,
        type: type,
        keyword: this.keyword
      }).then(function (res) {
        if (res.data.data.length == 0) {
          _this2.dkkd();
        }
        _this2.totle = res.data.total;
        _this2.last_page = res.data.last_page;
        if (_this2.last_page == 1) {
          _this2.right = true;
        } else {
          _this2.right = false;
        }
        if (type == 1) {
          _this2.zong = res.data.total;
        }
        _this2.right = false;
        _this2.left = false;
        // if (res.data.data.length == 0) {
        //     this.shuArr = true;
        // } else {
        //     this.shuArr = false;
        // }
        _this2.recordArr = res.data.data;
        _this2.fullscreenLoading = false;
        if (_this2.recordArr.length == 0) {
          _this2.shuArr = false;
          _this2.customer_id = '';
          _this2.customer_data_id = '';
        } else {
          _this2.customer_id = res.data.data[index].customer_id;
          _this2.customer_data_id = res.data.data[index].customer_data_id;
          if (_this2.customer_data_id != '') {
            _this2.shuArr = true;
          } else {
            _this2.shuArr = false;
          }
        }
        _this2.GetDetails(_this2.customer_id, _this2.customer_data_id);
        _this2.FollwupIndex(_this2.customer_id, _this2.customer_data_id);
        _this2.ajsgh = true;
      });
    },
    callClick: function callClick(tel) {
      // callout({
      //     cno: this.col,
      //     mobile: tel
      // }).then((res) => {
      //     qiConnect.call(tel);
      // });
      qiConnect.call(tel);
    },
    pageChange: function pageChange(e) {
      var val = e.target.value;
      if (val == '') {
        this.page = val;
      } else if (val > this.last_page) {
        val = this.last_page;
        this.page = val;
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      } else if (val <= 1) {
        val = 1;
        this.page = val;
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      } else {
        this.page = val;
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      }
    },
    prevClick: function prevClick() {
      this.page--;
      this.left = true;
      if (this.page < 1) {
        this.page = 1;
        this.left = true;
      } else if (this.page == 1) {
        this.left = true;
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      } else {
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      }
    },
    nextClick: function nextClick() {
      this.page++;
      this.right = true;
      if (this.page > 1) {
        this.left = false;
      }
      if (this.page == this.last_page) {
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
        this.right = true;
      } else if (this.page > this.last_page) {
        this.page = this.last_page;
        this.right = true;
      } else {
        this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
      }
    },
    inputClick: function inputClick() {
      this.page = 1;
      this.getCallLog(this.page, this.pagesize, this.tabClass, 0);
    },
    showTab: function showTab() {
      var _this3 = this;
      var activeTab = localStorage.getItem('activeName');
      if (activeTab) {
        this.activeName = activeTab;
        var data = {
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          pagesize: this.orderPagesize,
          page: this.orderPage
        };
        orderlist(data).then(function (res) {
          _this3.tableData = res.data.data;
          _this3.total = res.data.total;
        });
      }
      localStorage.setItem('activeName', '');
    },
    disabledShow: function disabledShow() {
      var detailList = localStorage.getItem('detailList');
      var addOrder = localStorage.getItem('addOrder');
      var editOrder = localStorage.getItem('editOrder');
      var orderDetail = localStorage.getItem('orderDetail');
      if (detailList) {
        this.detailListShow = true;
      }
      if (addOrder) {
        this.addOrderShow = true;
      }
      if (editOrder) {
        this.editOrderShow = true;
      }
      if (orderDetail) {
        this.orderDetailShow = true;
      }
    },
    // 删除空数组方法
    removeByValue: function removeByValue(arr, attr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i][attr] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    addOrder: function addOrder(val, sign) {
      var data = {
        customer_id: this.customer_id,
        customer_data_id: this.customer_data_id,
        create: this.create,
        edite: this.edite,
        id: val,
        edit: sign
        // name:this.detailForm.name,//客户名称
        // flower_name:this.detailForm.flower_name,//学员来源
        // category:this.detailForm.category,//客户类型
        //客户创建人
        //成交顾问
        //数据所属部门
        //客户总类
      };
      this.$router.push({
        path: '/orderInfo',
        query: data
      });
    },
    GetDetails: function GetDetails(customer_id, customer_data_id) {
      var _this4 = this;
      //编辑获取详情
      Customerinfo({
        customer_id: customer_id,
        customer_data_id: customer_data_id
      }).then(function (res) {
        var data = res.data;
        if (data.length != 0) {
          _this4.customer_id = res.data.customer_id;
          if (data.create_id != _this4.$store.getters.id) {
            _this4.noteApp = true;
          }
          if (data.create_id != _this4.$store.getters.id) {
            _this4.tgmcdisable = true;
          }
          if (data.create_id != _this4.$store.getters.id) {
            _this4.tgdzdisable = true;
          }
          _this4.disable = data.source_client_id !== '' ? true : false;
          _this4.detailForm.appended = data.create_notes;
          //意向专业
          _this4.detailForm.IntendedMajor = [];
          _this4.IntendedMajor = [];
          if (data.ittnzy != '') {
            data.ittnzy.forEach(function (item) {
              _this4.IntendedMajor.push(item);
              _this4.Intended = item.title.toString();
              _this4.detailForm.IntendedMajor.push(item.id);
            });
          }
          _this4.majorDedMaj();
          //意向简章
          _this4.GeneralRules = [];
          _this4.detailForm.GeneralRules = [];
          data.ittnjz.forEach(function (item) {
            _this4.GeneralRules.push(item);
            _this4.General = item.title.toString();
            _this4.detailForm.GeneralRules.push(item.id);
          });
          _this4.BrochuresList();
          // 意向院校
          _this4.institu = [];
          _this4.detailForm.institu = [];
          data.ittnyx.forEach(function (item) {
            _this4.institu.push(item);
            _this4.ins = item.title.toString();
            _this4.detailForm.institu.push(item.id);
          });
          _this4.SchoolDer();
          // 花名
          _this4.flowerNumber = [];
          // this.detailForm.flowerNameId = item.id
          if (data.source_client_id !== '') {
            data.source_client_info.forEach(function (item) {
              _this4.flowerNumber.push(item);
              _this4.detailForm.flowerNameId = item.id;
              _this4.detailForm.flower_name = item.flower_name;
              _this4.detailForm.category = item.leibie;
              _this4.detailForm.agent_num = item.number;
            });
          } else {
            _this4.detailForm.flowerNameId = '';
          }
          _this4.flowerArray();
          // 公海、私海
          _this4.regression = data.sea_type == 0 ? true : false;
          var khbq = data.labelpeer.split(',').map(function (res) {
            return res;
          });
          _this4.detailForm.name = data.cname;
          _this4.detailForm.Telephone = data.mobile;
          _this4.detailForm.cphoneAdress = data.mobile_address;
          _this4.detailForm.Telephone2 = data.mobile1;
          _this4.detailForm.cphoneAdress2 = data.mobile1_address;
          _this4.detailForm.Telephone3 = data.mobile2;
          _this4.detailForm.cphoneAdress3 = data.mobile2_address;
          _this4.detailForm.mailbox = data.cemail;
          _this4.detailForm.QQ = data.qq;
          _this4.detailForm.remarks = data.notes;
          _this4.detailForm.WeChat = data.wxnum;
          _this4.detailForm.extensionName = data.communication_name;
          _this4.detailForm.extensionAddress = data.communication_website;
          if (data.wxnum == '') {
            _this4.wxdisable = false;
          } else {
            _this4.wxdisable = true;
          }
          if (data.mobile1 == '') {
            _this4.mo1disable = false;
          } else {
            _this4.mo1disable = true;
            _this4.show2 = true;
          }
          if (data.mobile2 == '') {
            _this4.mo2disable = false;
          } else {
            _this4.mo2disable = true;
            _this4.show3 = true;
          }
          getGraduationMajor().then(function (res) {
            res.data.map(function (item) {
              item.id = String(item.id);
              if (item.id == String(data.graduation_xueli)) {
                _this4.educationText = item.value;
              }
            });
          });
          if (data.graduation_xueli == '0') {
            _this4.detailForm.educationText = '';
          } else {
            _this4.detailForm.educationText = String(data.graduation_xueli);
          }
          _this4.detailForm.areaOfInterest = data.ittngj;
          getIttnxm().then(function (res) {
            res.data.map(function (item) {
              item.id = String(item.id);
              if (item.id == data.ittnxl) {
                _this4.baokaixiangmu = item.value;
              }
            });
          });
          if (data.ittnxl == '0') {
            _this4.detailForm.projectTextId = '';
          } else {
            _this4.detailForm.projectTextId = String(data.ittnxl);
          }
          _this4.detailForm.GraduationMajor = data.graduation_major;
          _this4.detailForm.GraduatedFrom = data.graduate_school;
          _this4.detailForm.budget = data.budget;
          _this4.detailForm.sex = String(data.sex);
          if (data.entrance == '0') {
            _this4.detailForm.entranceTexts = '';
          } else {
            _this4.detailForm.entranceTexts = String(data.entrance); //预计入学
          }
          getEntrance().then(function (res) {
            //入学时间
            res.data.map(function (item) {
              item.id = String(item.id);
              if (item.id == _this4.detailForm.entranceText) {
                _this4.entranceTextEs = item.value;
                _this4.detailForm.entranceText = item.value;
              }
            });
            _this4.entrance = res.data;
          });
          _this4.detailForm.post = data.post;
          _this4.detailForm.achievements = data.academic_achievements;
          if (data.age == '0') {
            _this4.detailForm.Age = '';
          } else {
            _this4.detailForm.Age = String(data.age);
          }
          _this4.detailForm.Landline = data.telephone;
          _this4.detailForm.occupation = data.occupation;
          _this4.detailForm.language = data.language_achievement;
          _this4.detailForm.industry = data.industry;
          _this4.detailForm.Passport = data.passport_number;
          // this.detailForm.communication = data.communication_website;
          _this4.detailForm.address = data.detailed_address;
          _this4.detailForm.labelpeer = khbq;
          _this4.detailForm.Student = _this4.getFathersByid(data.customer_level, _this4.StudentLevel);
        } else {
          _this4.dkkd();
        }
      });
    },
    dkkd: function dkkd() {
      this.activities = [];
      this.detailForm = {
        name: '',
        //名称
        flower_name: '',
        //花名
        flowerNameId: 0,
        //花名id
        category: '',
        //类别
        agent_num: '',
        //花名码
        Telephone: '',
        //电话1
        cphoneAdress: '',
        //归属地1
        Telephone2: '',
        //电话2
        cphoneAdress2: '',
        //归属地2
        Telephone3: '',
        //电话3
        cphoneAdress3: '',
        //归属地3
        mailbox: '',
        //邮箱
        QQ: '',
        //qq
        remarks: '',
        //备注
        appended: '',
        WeChat: '',
        //微信
        extensionName: '',
        //推广名称
        extensionAddress: '',
        //推广地址
        educationText: '',
        //当前学历
        areaOfInterest: '',
        //意向地区
        projectTextId: '',
        //报考项目id
        projectText: '',
        //报考项目
        GraduationMajor: '',
        //毕业专业
        IntendedMajor: [],
        //意向专业
        GeneralRules: [],
        //意向简章
        GraduatedFrom: '',
        //毕业院校
        institu: [],
        //意向院校所选的值
        budget: '',
        //预算
        sex: '',
        //性别
        entranceText: '',
        //预计入学id
        entranceTexts: '',
        //预计入学val
        post: '',
        //岗位
        achievements: '',
        //学术成绩
        Age: '',
        //年龄
        Landline: '',
        //座机
        occupation: '',
        //单位
        language: '',
        //语言成绩
        industry: '',
        //行业
        Passport: '',
        //护照号
        // communication: '', //发起沟通的网址
        hobby: '',
        //性格
        address: '',
        //详细地址
        labelpeer: [],
        //客户标签
        Student: [] //学员级别
      };
    },
    // 编辑跳转页面
    editCustomer: function editCustomer() {
      this.$router.push({
        path: '/addCustomer?pid=2&customer_id=' + this.customer_id + '&customer_data_id=' + this.customer_data_id
      });
    },
    internationalWaters: function internationalWaters() {
      var _this5 = this;
      //回归公海
      this.$confirm('请确认是否将当前客户回归到公海？', '回归公海', {
        confirmButtonText: '继续回归',
        cancelButtonText: '取消'
      }).then(function () {
        MyCustomerRegression({
          customer_data_ids: _this5.customer_data_id
        }).then(function (res) {
          _this5.regression = false;
          _this5.$notify({
            title: '成功',
            message: '回归成功',
            type: 'success'
          });
          _this5.next_customer();
        });
      });
    },
    showEs: function showEs(e) {
      //编辑意向专业
      if (!e) {
        this.dblEditCustomer();
      }
    },
    GeneralEs: function GeneralEs(e) {
      //编辑意向简章
      if (!e) {
        this.dblEditCustomer();
      }
    },
    collegesEs: function collegesEs(e) {
      //编辑意向院校
      if (!e) {
        this.dblEditCustomer();
      }
    },
    flowerArray: function flowerArray(val) {
      var _this6 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        var hm = [];
        for (var i in _this6.flowerNumber) {
          if (_this6.flowerNumber[i] != '') {
            hm.push(_this6.flowerNumber[i]);
            for (var key in res.data) {
              hm.push(res.data[key]);
            }
          } else {
            for (var _key in res.data) {
              hm.push(res.data[_key]);
            }
          }
        }
        _this6.removeByValue(hm, 'id', 'null');
        _this6.flower_Array = _this6.dedupe(hm);
      });
    },
    flowerName: function flowerName() {
      var _this7 = this;
      //花名分页
      this.page++;
      agentFlowerNameList({
        page: String(this.page),
        pagesize: '15'
      }).then(function (res) {
        _this7.flower_Array.forEach(function (item) {
          _this7.flowerNumberStation.push(item);
        });
        for (var key in res.data.data) {
          _this7.flowerNumberStation.push(res.data.data[key]);
        }
        _this7.removeByValue(_this7.flowerNumberStation, 'id', 'null');
        var array = _this7.dedupe(_this7.flowerNumberStation);
        _this7.flower_Array = array;
      });
    },
    flowerArrayEs: function flowerArrayEs(val) {
      var _this8 = this;
      //改变花名
      agentFlowerNameList({
        page: String(1),
        pagesize: '15',
        key_word: val
      }).then(function (res) {
        res.data.forEach(function (item) {
          if (val == item.id) {
            _this8.detailForm.category = item.leibie;
            _this8.ruleForm.agent_num = item.number;
          }
        });
        _this8.flower_Array = res.data;
      });
    },
    flower: function flower(e) {
      //编辑花名
      if (!e) {
        this.dblEditCustomer();
      }
    },
    BrochuresList: function BrochuresList() {
      var _this9 = this;
      //意向简章默认
      _BrochuresList().then(function (res) {
        res.data.brochures_list.map(function (item) {
          item.id = String(item.id);
        });
        var zxjz = [];
        for (var i in _this9.GeneralRules) {
          zxjz.push(_this9.GeneralRules[i]);
          for (var key in res.data.brochures_list) {
            zxjz.push(res.data.brochures_list[key]);
          }
        }
        _this9.GenalRules = res.data.brochures_list;
      });
    },
    majorDedMaj: function majorDedMaj() {
      var _this10 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        res.data.major_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxzy = [];
        for (var i in _this10.IntendedMajor) {
          yxzy.push(_this10.IntendedMajor[i]);
          for (var key in res.data.major_list) {
            yxzy.push(res.data.major_list[key]);
          }
        }
        _this10.Intendeds = res.data.major_list;
      });
    },
    SchoolDer: function SchoolDer() {
      var _this11 = this;
      //意向院校默认
      School().then(function (res) {
        res.data.school_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxyx = [];
        for (var i in _this11.institu) {
          yxyx.push(_this11.institu[i]);
          for (var key in res.data.school_list) {
            yxyx.push(res.data.school_list[key]);
          }
        }
        _this11.chatmodes = res.data.school_list;
      });
    },
    changeShow: function changeShow(value, type) {
      this.changeShowValue(value, type);
    },
    GETPlaceofownership: function GETPlaceofownership(cphone, number) {
      var _this12 = this;
      //手机号验证/查询归属地
      if (!/^1[3456789]\d{9}$/.test(cphone)) {
        this.$notify({
          title: '警告',
          message: '手机号错误',
          type: 'warning'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        if (this.detailForm.Telephone === this.detailForm.Telephone2) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone2 = '';
        } else if (this.detailForm.Telephone2 === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else if (this.detailForm.Telephone === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else {
          GetPhoneAddress({
            phone: cphone
          }).then(function (res) {
            _this12.Show = true;
            if (number == 1) {
              _this12.detailForm.cphoneAdress = res.data.province + res.data.city;
            } else if (number == 2) {
              _this12.detailForm.cphoneAdress2 = res.data.province + res.data.city;
            } else if (number == 3) {
              _this12.detailForm.cphoneAdress3 = res.data.province + res.data.city;
            }
          });
        }
      }
    },
    sendEmail: function sendEmail() {
      //邮箱验证
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.detailForm.mailbox != '' && !regEmail.test(this.detailForm.mailbox)) {
        this.$message.error('邮箱格式不正确');
        this.detailForm.mailbox = '';
      }
    },
    QQverification: function QQverification(val) {
      //QQ号验证
      var regEmail = /^[1-9][0-9]{7,10}$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('QQ号格式不正确');
        this.detailForm.QQ = '';
      }
    },
    Landlines: function Landlines(val) {
      //座机号验证
      var regEmail = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('座机号格式不正确');
        this.detailForm.Landline = '';
      }
    },
    displayShow: function displayShow() {
      // 联系电话2和3的显示
      if (this.show2 == false) {
        this.show2 = true;
      } else {
        this.show3 = true;
      }
    },
    mobilePhone: function mobilePhone(number) {
      //隐藏清空电话
      if (number == 2) {
        this.show2 = false;
        this.detailForm.Telephone3 = '';
        this.detailForm.cphoneAdress3 = '';
      } else {
        this.show3 = false;
        this.detailForm.Telephone3 = '';
        this.detailForm.cphoneAdress3 = '';
      }
    },
    education: function education() {
      var _this13 = this;
      //进入页面加载的接口
      getGraduationMajor().then(function (res) {
        //当前学历
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this13.educations = res.data;
      });
      getIttnxm().then(function (res) {
        //报考项目
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this13.projects = res.data;
      });

      // getEntrance().then(res => {
      //     //入学时间
      //     res.data.map(item => {
      //         item.id = String(item.id);
      //     });
      //     this.entrance = res.data;
      // });
      getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this13.customer = res.data;
      });
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this13.StudentLevel = res.data;
      });
      this.FollwupIndex(this.customer_id, this.customer_data_id);
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    educationSe: function educationSe(val) {
      var _this14 = this;
      //当前学历
      this.detailForm.educationText = val;
      getGraduationMajor().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this14.educationText = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    projectSe: function projectSe(val) {
      var _this15 = this;
      //报考项目
      this.detailForm.projectTextId = val;
      getIttnxm().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this15.detailForm.projectText = item.value;
            _this15.dblEditCustomer();
          }
        });
      });
    },
    detailFormSex: function detailFormSex(val) {
      //男女
      this.detailForm.sex = val;
      this.dblEditCustomer();
    },
    detailFormEntrance: function detailFormEntrance(val) {
      var _this16 = this;
      //入学时间
      this.detailForm.entranceTexts = val;
      getEntrance().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this16.detailForm.entranceTextEs = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    handleChange: function handleChange(val) {
      if (val == '') {
        this.detailForm.Student = '';
      } else {
        this.detailForm.Student = val[1] == undefined ? val[0] : val[1];
        this.dblEditCustomer();
      }
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    FollwupIndex: function FollwupIndex(customer_id, customer_data_id) {
      var _this17 = this;
      this.activities = '';
      _FollwupIndex({
        id: Number(customer_id),
        customer_data_id: Number(customer_data_id),
        is_realname: 2
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this17.activities = res.data;
      });
    },
    NewFollowupRecordSure: function NewFollowupRecordSure() {
      var _this18 = this;
      //添加跟进
      if (this.form.typese == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.form.theme == '') {
        this.$message({
          type: 'warning',
          message: '请填写内容'
        });
      } else {
        FollwupAdd({
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          content: this.form.theme,
          type: Number(this.form.typese),
          next_time: this.form.next_follow.toString().substr(0, 10)
        }).then(function (res) {
          //入学时间
          _this18.FollwupIndex(_this18.customer_id, _this18.customer_data_id);
          _this18.$notify({
            title: '成功',
            message: '新增跟进成功',
            type: 'success'
          });
          _this18.form.theme = '';
          _this18.form.typese = '1';
          _this18.form.next_follow = '';
          _this18.dialogFormVisible = false;
          //this.getTimestamp(
        });
      }
    },
    dblEditCustomer: function dblEditCustomer() {
      var _this19 = this;
      //编辑信息
      var detailForm = this.detailForm;
      if (detailForm.name == '') {
        this.$message({
          type: 'warning',
          message: '客户名称输入有误'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        var data = {
          customer_id: this.customer_id,
          customer_data_id: this.customer_data_id,
          cname: detailForm.name,
          //客户名称
          source_client_id: String(detailForm.flowerNameId == undefined ? '' : detailForm.flowerNameId),
          //花名
          mobile: detailForm.Telephone,
          //手机号
          mobile1: detailForm.Telephone2,
          //手机号1
          mobile2: detailForm.Telephone3,
          //手机2
          qq: detailForm.QQ,
          //qq号
          wxnum: detailForm.WeChat,
          //微信号
          communication_name: detailForm.extensionName,
          //推广名称
          communication_website: detailForm.extensionAddress,
          cemail: detailForm.mailbox,
          //邮箱
          sex: detailForm.sex,
          //性别 0男 1女
          age: detailForm.Age,
          //年龄
          entrance: detailForm.entranceTexts,
          //预计入学
          post: detailForm.post,
          //岗位
          company: detailForm.occupation,
          //单位
          academic_achievements: detailForm.achievements,
          //学术成绩
          passport_number: detailForm.Passport,
          //护照号码
          language_achievement: detailForm.language,
          //语言成绩
          // communication_website: detailForm.communication, //发起沟通网址
          detailed_address: detailForm.address,
          //地址
          character: detailForm.hobby,
          //性格
          customer_level: detailForm.Student,
          //学员级别
          mobile_address: detailForm.cphoneAdress,
          //手机归属地1
          mobile1_address: detailForm.cphoneAdress2,
          //手机归属地2
          mobile2_address: detailForm.cphoneAdress3,
          //手机归属地3
          graduation_major: detailForm.GraduationMajor,
          //毕业专业
          graduate_school: detailForm.GraduatedFrom,
          //毕业院校
          ittngj: detailForm.areaOfInterest,
          //意向地区
          ittnzy: String(detailForm.IntendedMajor),
          //意向专业id 1,2,3
          ittnyx: String(detailForm.institu),
          //意向院校id 1,2,3
          ittnxm: detailForm.projectTextId,
          //报考项目id
          ittnjz: String(detailForm.GeneralRules),
          //意向简章id 1,2,3
          budget: detailForm.budget,
          //预算
          labelpeer: String(detailForm.labelpeer),
          //客户标签 1,2,3
          notes: detailForm.remarks,
          //备注
          telephone: detailForm.Landline,
          //座机
          occupation: detailForm.occupation,
          //职业
          industry: detailForm.industry,
          //行业
          create_notes: detailForm.appended,
          //创建附注
          graduation_xueli: detailForm.educationText
        };
        MyCustomerUpdate(data).then(function (res) {
          _this19.GetDetails(_this19.customer_id, _this19.customer_data_id);
          _this19.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success',
            duration: 2000
          });
        });
      }
    },
    changeShowValue: function changeShowValue(value, type) {
      //悬浮状态
      if (this.shuArr) {
        if (Number(this.editRole)) {
          if (type) {
            var showArr = ['intentionally', 'agent_name', 'xuel', 'timeline', 'adress'];
            var showNum = 0;
            for (var key in showArr) {
              if (this.detailFormShow[showArr[key]] == false) {
                showNum = 1;
              }
            }
            for (var _key2 in this.detailFormShow) {
              if (_key2 == value) {
                this.detailFormShow[_key2] = false;
              } else if (showNum == 1) {
                this.detailFormShow[_key2] = true;
              }
            }
          } else {
            for (var _key3 in this.detailFormShow) {
              if (_key3 == value) {
                this.detailFormShow[_key3] = true;
              }
            }
          }
        }
      } else {
        this.detailFormShow = {
          cname: !this.shuArr,
          intentionally: !this.shuArr,
          cphone: !this.shuArr,
          agent_name: !this.shuArr,
          area: !this.shuArr,
          noteAppEs: !this.shuArr,
          adress: !this.shuArr,
          note: !this.shuArr,
          wxnum: !this.shuArr,
          communication_name: !this.shuArr,
          communication_website: !this.shuArr,
          telephone: !this.shuArr,
          cemail: !this.shuArr,
          qq: !this.shuArr,
          cphonetwo: !this.shuArr,
          cphonethree: !this.shuArr,
          ittnzy: !this.shuArr,
          ittnzyShow: !this.shuArr,
          ittnxl: !this.shuArr,
          ittnxlShow: !this.shuArr,
          ittnyx: !this.shuArr,
          ittnyxShow: !this.shuArr,
          ittnxm: !this.shuArr,
          ittnxmShow: !this.shuArr,
          ittngj: !this.shuArr,
          budget: !this.shuArr,
          xuel: !this.shuArr,
          graduate: !this.shuArr,
          graduatezy: !this.shuArrfalse,
          xueshuchengji: !this.shuArr,
          yuyanchengji: !this.shuArr,
          timeline: !this.shuArr,
          sex: !this.shuArr,
          age: !this.shuArr,
          station: !this.shuArr,
          industry: !this.shuArr,
          character: !this.shuArr,
          occupation: !this.shuArr,
          hzID: !this.shuArr,
          hobby: !this.shuArr,
          tolink: !this.shuArr,
          next_follow: !this.shuArr,
          groupName: !this.shuArr,
          groupNameShow: !this.shuArr
        };
      }
    },
    //zml下次回访条数
    getUnfollowmsg: function getUnfollowmsg() {
      var _this20 = this;
      var next_time = this.form.next_follow.toString().substr(0, 10);
      getUnfollow({
        next_time: next_time
      }).then(function (res) {
        if (res.data == '') {
          _this20.Unfollowshow = false;
        } else {
          _this20.Unfollowshow = true;
          _this20.Unfollow = res.data;
        }
      });
    }
  }
};